<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t('dialogMessages.deleteConfirmation') }} "{{
            selectedEvent.name
          }}"?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          {{ $t('buttons.today') }}
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>{{ $t('buttons.day') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>{{ $t('buttons.week') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>{{ $t('buttons.month') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>{{ $t('buttons.days') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        :locale="locale"
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        :interval-minutes="60"
        interval-height="20"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        :weekdays="weekdays"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-btn icon @click="redirectToClaim">
              <v-icon>mdi-location-enter</v-icon>
            </v-btn>
            <v-toolbar-title
              v-show="!editMessage"
              v-html="selectedEvent.name"
              @dblclick="toggleEditMessage"
            ></v-toolbar-title>
            <!-- <v-toolbar-title v-show="editMessage" @dblclick="toggleEditMessage"> -->
            <v-text-field
              ref="message"
              v-show="editMessage"
              @dblclick="toggleEditMessage"
              @keyup.esc="toggleEditMessage"
              @keyup.enter="submitMessageChange"
              v-model="message"
              clearable
              color="success"
            ></v-text-field>
            <v-btn v-show="editMessage" @click="submitMessageChange"
              >Save</v-btn
            >
            <!-- </v-toolbar-title> -->

            <!-- <h3 class="title is-3" v-show="!editTitle" @dblclick="toggleEditTitle">
        {{ bundle && bundle.title }}
      </h3>
      <input
        class="input is-success"
        type="text"
        ref="title"
        v-show="editTitle"
        v-model="title"
        @dblclick="toggleEditTitle"
        @keyup.esc="toggleEditTitle"
        @keyup.enter="submitTitleChange"
      /> -->
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
          </v-toolbar>
          <v-card-text>
            <v-row v-show="!editTime">
              <div class="text-h6 isLink">
                {{ formatTimeAndDate(selectedEvent.start) }}
              </div>
              <v-icon @click="toggleTimeEdit" small>mdi-pencil</v-icon>
            </v-row>
            <v-row v-show="editTime" class="elevation-4">
              <v-col
                cols="4
                "
              >
                <v-menu
                  v-model="reminder_datepicker_show"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="date" v-on="on"> </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="reminder_datepicker_show = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-model="reminder_timepicker_show"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="290px"
                  max-width="290px"
                  @input="
                    (value) =>
                      value &&
                      $refs.picker &&
                      ($refs.picker.selectingHour = true)
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="time" v-on="on"> </v-text-field>
                  </template>
                  <v-time-picker
                    ref="picker"
                    format="24hr"
                    v-model="time"
                    scrollable
                    no-title
                    @click:minute="reminder_timepicker_show = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-btn class="ma-2" @click="toggleTimeEdit">Cancel</v-btn>
                <v-btn class="ma-2" color="success" @click="submitTimeChange"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
            <span v-html="selectedEvent.details"></span>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
            <v-btn class="ml-auto" color="error" @click="dialogDelete = true"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
export default {
  data: function () {
    return {
      focus: '',
      type: 'month',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogDelete: false,
      editMessage: false,
      editTime: false,
      date: '',
      time: '',
      message: '',
      reminder_datepicker_show: false,
      reminder_timepicker_show: false,
      events: [],
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      names: [
        'Meeting',
        'Holiday',
        'PTO',
        'Travel',
        'Event',
        'Birthday',
        'Conference',
        'Party',
      ],
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    }
  },
  mounted() {
    this.$store.dispatch('reminder/getReminders').then(() => {})

    this.$refs.calendar.checkChange()
  },
  methods: {
    redirectToClaim() {
      if (this.selectedEvent.pipelineSlug) {
        this.$router.push(
          `${this.selectedEvent.pipelineSlug}/${this.selectedEvent.claimId}`
        )
      }
    },
    intervalFormat(interval) {
      return interval.time
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    moreEventsClick(details) {
      this.focus = details.date
      this.type = 'day'
    },
    resetCalendar() {
      this.focus = ''
      this.type = '4day'
    },
    deleteItemConfirm() {
      this.$store.dispatch('reminder/deleteReminder', this.selectedEvent.id)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
    },
    toggleEditMessage() {
      this.editMessage = !this.editMessage
      this.message = this.selectedEvent.name
      if (this.editMessage) {
        this.$nextTick(() => {
          this.$refs.message.focus()
        })
      }
    },
    toggleTimeEdit() {
      this.editTime = !this.editTime
      this.time = dayjs(this.selectedEvent.start).format('HH:mm')
      this.date = dayjs(this.selectedEvent.start).format('YYYY-MM-DD')
    },
    submitMessageChange() {
      if (this.message && this.message != '') {
        let payload = {
          id: this.selectedEvent.id,
          message: this.message,
        }
        this.$store.dispatch('reminder/updateReminder', payload).then(() => {
          this.selectedEvent = this.events.find(
            (event) => event.id == this.selectedEvent.id
          )
        })
        this.toggleEditMessage()
      }
    },
    submitTimeChange() {
      if (this.date != '' && this.time != '') {
        let [hours, minutes] = this.time.split(':')
        let dateToSubmit = new Date(
          dayjs(this.date).add(hours, 'h').add(minutes, 'm')
        )
        let payload = {
          id: this.selectedEvent.id,
          triggerAt: dateToSubmit,
        }
        this.$store.dispatch('reminder/updateReminder', payload).then(() => {
          this.selectedEvent = this.events.find(
            (event) => event.id == this.selectedEvent.id
          )
        })
        this.toggleTimeEdit()
      }
    },
    formatTimeAndDate(dateAndTime) {
      return dayjs(dateAndTime).format('YYYY-MM-DD HH:mm')
    },
  },
  computed: {
    ...mapState({
      reminders: (state) => state.reminder.reminders,
    }),
    locale() {
      return this.$i18n.locale
    },
    typeToLabel() {
      return {
        month: this.$i18n.t('buttons.month'),
        week: this.$i18n.t('buttons.week'),
        day: this.$i18n.t('buttons.day'),
        '4day': this.$i18n.t('buttons.days'),
      }
    },
  },
  watch: {
    reminders() {
      this.events = this.reminders.map((r) => ({
        id: parseInt(r.id),
        color: 'cyan',
        name: r.message,
        start: dayjs(r.triggerAt).toDate(),
        end: dayjs(r.triggerAt).add(30, 'minutes').toDate(),
        timed: true,
        claimId: parseInt(r.claimId),
        pipelineSlug: r.pipelineSlug ? r.pipelineSlug : null,
      }))
    },
  },
}
</script>

<style lang="scss" scoped></style>
